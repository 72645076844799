@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_text_image {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: 30px;
      @include mq(tbl) {
        width: 45%;
        margin-top: 0;
      }
    }
    .image {
      @include mq(tbl) {
        width: 51%;
        margin-top: 0;
        padding-top: 10px;
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tbl) {
        flex-direction: row-reverse;
      }
    }
  }
}
