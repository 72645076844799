@charset "UTF-8";
@import "variables";
@import "mixins";

.m_pagenate {
  margin-top: 50px;
  @include container;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .m_newslist + & {
    margin-top: 40px;
    @include mq(tbl) {
      margin-top: 50px;
    }
    .inner {
      padding-top: 0;
      border-top: none;
    }
  }
  .inner {
    padding-top: 40px;
    border-top: 1px solid #CCCBCA;
    @include fs(14,14);
    font-weight: $font-weight-bold;
    @include mq(tbl) {
      padding-top: 60px;
      @include fs(18,18);
    }
    a, span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: block;
      border-radius: 50%;
      background-color: #EBEBEB;
      @include mq(tbl) {
        @include hoverOp;
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
      &.current, &.current_page {
        background-color: $color-main;
        color: white;
        @include mq(tbl) {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.nextpostslink, &.link_before, &.link_next {
        background-color: transparent;
        text-decoration: underline;
      }
      &.previouspostslink, &.link_before {
        img {
          transform: rotate(180deg);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 3px 15px;

      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 5px 15px;
        @include mq(tbl) {
          margin: 0 10px 20px;
        }
      }
    }
  }
}
.m_pagenate_detail {
  margin-top: 50px;
  @include container;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    padding-top: 40px;
    padding-bottom: 65px;
    position: relative;
    border-top: 1px solid #ccc;
    font-family: $font-family-main;
    display: flex;
    justify-content: space-between;
    @include mq(tbl) {
      padding-top: 60px;
      padding-bottom: 0;
      justify-content: center;
    }
    span {
      display: inline-block;
      @include mq(tbl) {
        min-width: 200px;
      }
      a {
        display: inline-block;
        position: relative;
        background-color: #EBEBEB;
        color: $black;
        @include fs(16,28);
        padding: 8px 35px;
        border-radius: 6px;
        font-family: $font-family-main;
        @include fs(14,24);
        text-align: center;
        min-width: 120px;
        @include mq(tbl) {
          padding: 10px 35px;
          @include fs(16,28);
          min-width: 200px;
          margin: 0 10px;
          @include hoverOp(.5);
        }
        svg {
          position: absolute;
          right: 18px;
          top: 50%;
          transform: translateY(-50%);
          transform-origin: top center;
          width: 8px;
        }
      }
      &.back {
        position: absolute;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        @include mq(tbl) {
          position: static;
          transform: translateX(0);
        }
        a {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &.prev {
        a {
          svg {
            right: auto;
            left: 18px;
            transform: rotate(180deg) translateY(-50%);
          }
        }
      }
    }
  }
}
