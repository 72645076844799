@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_image {
  margin-top: 35px;
  @include mq(tbl) {
    margin-top: 55px;
  }
  .inner {
    @include container;
    .image {
      &.shrink {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
