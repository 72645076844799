$container: 980px;
$container-wide: 1200px;

$main: #085DB4;
$white: #fff;
$red: #E60012;
$black: #333;
$main-dark:#245DB4;
$main-light:#0361FE;
$sub:#FFFF00;
$lightgray: #ccc;

$color-main: #085DB4;

$radius-small:2px;
$radius-default: 4px;

// transitions settings

$bezier: cubic-bezier(.25,.1,.25,1);
$transition-speed: .5s;
$transition-speed-fast: .3s;

// font settings

$font-family-default: 'Helvetica Neue',Arial,'Hiragino Kaku Gothic ProN','Hiragino Sans',Meiryo,sans-serif;

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap');
$font-family-main: 'Noto Sans JP', sans-serif;
$font-family-sub: 'Noto Sans JP', sans-serif;

$font-weight-normal: 500;
$font-weight-bold: 700;

// body settings

$body-text-color: $black;
$body-bg-color: $white;
$body-font-family: $font-family-main;
$body-font-weight: normal;
$body-font-feature-settings: 'palt';
$body-letter-spacing: 1px;

// debug settings

$responsive-debug-mode: false;

// header settings

$header-height: 60px;
$header-height-pc: 90px;
$header-background-color:white;
$header-background-color-pc:white;
$header-background-color-top: white;
$header-background-color-top-pc: white;
$header-z-index: 100;

// header logo settings

$header-logo-padding: 0 0 0 20px;
$header-logo-padding-pc: 0 35px 0 30px;
$header-logo-width: 134px;
$header-logo-z-index: 1002;

// header-is-down settings

$header-transition: $bezier;
$header-transition-speed: $transition-speed;
$header-is-down-transform: translateY(-100%);

// gnav settings

$gnav-background-color: $color-main;
$gnav-background-color-pc: transparent;
$gnav-padding: 22px 30px 40px;
$gnav-z-index: 1001;

$gnav-main-font-family: $font-family-main;

$gnav-main-list-padding: 13px 0;
$gnav-main-list-padding-spl: 10px 0;

$gnav-main-list-font-size: 1.8rem;
$gnav-main-list-line-height: 1.5;
$gnav-main-list-font-color: white;
$gnav-main-list-font-color-top: white;

$gnav-main-list-line-height-pc: 1;
$gnav-main-list-font-color-pc: $black;
$gnav-main-list-font-color-top-pc: white;

$gnav-main-list-font-size-spl: 1.6rem;
$gnav-main-list-line-height-spl: 1;
$gnav-main-list-font-color-spl: $gnav-main-list-font-color;
$gnav-main-list-font-color-top-spl: $gnav-main-list-font-color;

$gnav-main-list-current-opacity: 0.3;
$gnav-main-list-current-underline-color: #918E7F;
$gnav-main-list-current-underline-position: -3px;

// sns settings

$header-sns-margin: 35px 0 0 0;
$header-sns-margin-spl: 15px 0 0 0;
$header-sns-list-margin-right: 20px;

$header-sns-icon-width: 60px;
$header-sns-icon-height: 60px;

$header-sns-icon-width-spl: 40px;
$header-sns-icon-height-spl: 40px;

$header-sns-icon-image-width: 30px;
$header-sns-icon-image-width-spl: 20px;
$header-sns-icon-background-color: white;
$header-sns-icon-color: #948F7F;
$header-sns-icon-radius: 50%;

// store icon settings

$header-store:true;
$header-store-width:$header-height;

$header-store-background-color:#F5F4F0;
$header-store-background-color-pc:$color-main;
$header-store-background-color-pc-hover:lighten($color-main, 20%);

$header-store-font-family: $font-family-sub;
$header-store-font-family-pc: $font-family-main;

$header-store-padding: 0 8px;

$header-store-icon-width: 24px;

$header-store-icon-color: $color-main;
$header-store-icon-color-pc: white;

$header-store-text-color: $color-main;
$header-store-text-color-pc: white;

$header-store-font-size: 1rem;

// menu button settings

$menu-backgound-color: $color-main;
$menu-z-index: 1002;
$menu-bar-backgound-color: white;
$menu-bar-width: 30px;
$menu-bar-height: 3px;
$menu-bar-border-radius: 6px;
$menu-bar-top-pos: 19px;
$menu-bar-middle-pos: 28px;
$menu-bar-bottom-pos: 37px;


$path-transition: $bezier 1.5s forwards;

$imagepath: '/images/';

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1024px)',
  'pc': 'print, screen and (min-width: 1280px)',
) !default;
