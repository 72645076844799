@charset "UTF-8";
@import "variables";
@import "mixins";

.m_breadcumbs {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    @include container;
    ul {
      @include fs(13,21);
      display: flex;
      flex-wrap: wrap;
      li {
        @include fs(14,24);
        margin-right: 5px;
        &:after {
          content: '\FF0F';
          margin-left: 5px;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          text-decoration: underline;
          color: $black;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
