@charset "UTF-8";
@import "variables";
@import "mixins";

.m_localnav_bottom {
  margin-top: 60px;
  background-color: #F3F5F6;
  @include mq(tbl) {
    margin-top: 120px;
  }
  .inner {
    @include container;
    padding-top: 20px;
    padding-bottom: 10px;
    @include mq(tbl) {
      padding-top: 70px;
      padding-bottom: 50px;
    }
    ul {
      @include mq(tbl) {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        margin-bottom: 10px;
        @include mq(tbl) {
          margin: 0 5% 22px 0;
          width: 30%;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        &.active {
          a {
            color: $main;
            border-bottom: 2px solid $main;
          }
        }
        a {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ccc;
          padding-bottom: 9px;
          &:hover {
            @include mq(tbl) {
              border-bottom-color: $main;
              color: $main;
            }
          }
          i {
            flex: 0 1 auto;
            width: 60px;
            height: 60px;
            background: white center / 50% no-repeat url(/images/logomark.svg);
            margin-right: 15px;
            @include mq(tbl) {
              margin-right: 25px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          span {
            flex: 1;
            position: relative;
            padding-left: 25px;
            @include mq(tbl) {
              @include fs(16,26);
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        &.active,&.current_page_item {
          a {

          }
        }
      }
    }
  }
}
