@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_flow {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .container {
    @include container;
    .inner {
      dl {
        display: flex;
        &:last-child {
          dd {
            padding-bottom: 0;
            &::before {
              display: none;
            }
          }
        }
        dt {
          @include fs(18,28);
          font-weight: $font-weight-bold;
          padding-right: 15px;
          @include mq(tbl) {
            @include fs(24,40);
            padding-right: 35px;
          }
        }
        dd {
          @include fs(15,28);
          padding-bottom: 25px;
          padding-left: 20px;
          position: relative;
          @include mq(tbl) {
            @include fs(16,30);
            padding-bottom: 45px;
            padding-left: 40px;
          }
          h3 {
            @include fs(18,28);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              @include fs(24,40);
            }
          }
          p {
            margin-top: .25em;
          }
          &::before {
            @include pd;
            left: 3px;
            width: 2px;
            height: 100%;
            top: 10px;
            bottom: 0;
            background-color: $main;
            @include mq(tbl) {
              width: 4px;
              left: 6px;
              top: 14px;
            }
          }
          &::after {
            @include pd;
            left: 0px;
            width: 8px;
            height: 8px;
            top: 10px;
            border-radius: 50%;
            background-color: $main;
            @include mq(tbl) {
              width: 16px;
              height: 16px;
              top: 14px;
            }
          }
        }
      }
    }
  }
}
