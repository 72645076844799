@charset "UTF-8";
@import "variables";
@import "mixins";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  @include mq(tbl) {
    margin: 0 5px;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


.m_slider {
  display: none;
  &.slick-initialized {
    display: block;
  }
  .unit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    @include mq(tbl) {
      display: block;
      width: $container;
      height: 408px;
    }
    @include mq(pc) {
      width: $container-wide;
      height: 500px;
    }
    > a {
      display: block;
      position: relative;
      z-index: 1;
    }
    &::before {
      @include pd;
      @include mq(tbl) {
        z-index: 11;
        width: $container;
        height: 408px;
        background-color: $black;
        opacity: .5;
        transition: opacity .2s ease;
      }
      @include mq(pc) {
        width: $container-wide;
        height: 500px;
      }
    }
    .text {
      padding: 17px 20px 0;
      background-color: $main;
      @include mq(tbl) {
        background-color: transparent;
        position: absolute;
        width: 320px;
        left: 65px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        z-index: 10;
      }
      &.right {
        text-align: right;
        @include mq(tbl) {
          text-align: left;
          left: auto;
          right: 65px;
        }
        a {
          @include mq(tbl) {
            margin-left: auto;
          }
        }
      }
      &.center {
        text-align: center;
        @include mq(tbl) {
          text-align: left;
          width: 400px;
          left: 50%;
          right: auto;
          transform: translateX(-50%) translateY(-50%);
        }
        .button {
          text-align: center;
        }
      }
      .textinner {
        display: inline-block;
      }
      h1 {
        @include fs(18,28);
        color: $white;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(34,44);
        }
        + p {
          margin-top: 10px;
          @include mq(tbl) {
            margin-top: 20px;
          }
        }
      }
      p {
        @include fs(13,21);
        color: $white;
        margin: 0;
        @include mq(tbl) {
          @include fs(15,26);
        }
      }
      &.black {
        h1, p {
          @include mq(tbl) {
            color: $black;
          }
        }
      }
      .button {
        text-align: right;
        a {
          background-color: $sub;
          @include fs(12,12);
          font-weight: $font-weight-bold;
          color: $white;
          margin-top: 12px;
          display: inline-block;
          padding: 12px 75px 12px 30px;
          position: relative;
          color: $black;
          min-width: 150px;
          margin-left: auto;
          @include mq(tbl) {
            margin-top: 30px;
            text-align: center;
            min-width: 200px;
            margin-left: 0;
            @include fs(14,14);
            padding: 14px 75px;
            @include hoverOp;
          }
          &:before {
            @include pd;
            background: url(#{$imagepath}arrow_right_large.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 28px;
            height: 16px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: 85vw;
      background-position: center;
      background-size: cover;
      transition: opacity .2s ease;
      @include mq(tbl) {
        display: block;
        width: $container;
        height: 408px;
      }
      @include mq(pc) {
        width: $container-wide;
        height: 500px;
      }
    }
    &.slick-active::before, &.is-active-next::before {
      opacity: 0;
      z-index: 0;
    }
    .bg.pc {
      display: none;
      @include mq(tbl) {
        display: block;
      }
    }
    .bg.sp {
      display: block;
      @include mq(tbl) {
        display: none;
      }
    }
    &.with_bg {
      &.left {
        @include mq(tbl) {
          &:after {
            @include pd;
            clip-path: polygon(0 0, 40% 0, 24% 100%, 0% 100%);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            background-color: $main;
          }
        }
      }
      &.right {
        @include mq(tbl) {
          &:after {
            @include pd;
            clip-path: polygon(76% 0, 100% 0, 100% 100%, 60% 100%);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            background-color: $main;
          }
        }
      }
      .text {
        .textinner {
          @include mq(tbl) {
            display: inline-block;
          }
        }
        &.left {
          p {
            @include mq(tbl) {
              padding-right: 60px;
            }
          }
          a {
            @include mq(tbl) {
              margin-left: auto;
            }
          }
        }
        &.right {
          @include mq(tbl) {
            width: 300px;
            right: 35px;
            top: auto;
            transform: translateY(0);
            bottom: 40px;
          }
          h1 {
            @include mq(tbl) {
              @include fs(28,38);
            }
          }
          a {
            @include mq(tbl) {
              margin-left: auto;
            }
          }
        }
        &.center {
          @include mq(tbl) {
            background-color: $main;
            padding: 40px;
            width: 480px;
          }
          h1 {
            @include mq(tbl) {
              @include fs(28,38);
            }
          }
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 20px;
    z-index: 10;
    cursor: pointer;
    @include mq(tbl) {
      top: 50%;
      left: 30px;
      transform: translate(0, -40px);
      @include hoverOp(.4);
    }
    @include mq(pc) {
      transform: translate(0, -35px);
    }
    img {
      width: 30px;
      transform: rotate(180deg);
      @include mq(tbl) {
        width: 40px;
        opacity: .7;
      }
    }
    &.slick-next {
      left: auto;
      right: 20px;
      @include mq(tbl) {
        left: auto;
        right: 30px;
        transform: translate(0, -40px);
      }
      @include mq(pc) {
        transform: translate(0, -35px);
      }
      img {
        transform: rotate(0deg);
      }
    }
  }
  .slick-dots {
    text-align: center;
    list-style: none;
    margin: 30px 0 11px;
    padding: 0;
    @include mq(tbl) {
      text-align: right;
      margin: 20px 0 0;
      @include container(70px,wide);
    }
    li {
      display: inline-block;
      width: auto;
      padding: 0;
      margin: 0 5px;
      @include mq(tbl) {
        margin: 0 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.slick-active button {
        background-color: $main;
      }
      button {
        border: none;
        background-color: #EBEBEB;
        color: transparent;
        width: 20px;
        height: 4px;
        padding: 0;
        cursor: pointer;
        @include mq(tbl) {
          width: 40px;
        }
      }
    }
  }
}
