@charset "UTF-8";
@import "variables";
@import "mixins";

footer {
  background-color: #6C6F73;
  .inner {
    @include container;
    color: $white;
    @include mq(tbl) {
      display: flex;
      flex-direction: row-reverse;
      padding-top: 70px;
    }
    .footnav {
      padding-top: 45px;
      @include mq(tbl) {
        padding-top: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, auto);
        grid-gap: 16px;
        width: 75%;
      }
      .unit {
        border-top: 1px solid #999;
        position: relative;
        @include mq(tbl) {
          border-top: none;
        }
        &:last-child {
          border-bottom: 1px solid #999;
          @include mq(tbl) {
            border-bottom: none;
          }
        }
        h2 {
          @include fs(16,24);
          padding: 13px 10px;
          position: relative;
          @include mq(tbl) {
            @include fs(18,28);
            padding: 0 0 0 25px;
          }
          a {
            color: $white;
            position: relative;
            pointer-events: none;
            @include mq(tbl) {
              pointer-events: all;
              display: inline-block;
              padding-left: 25px;
              @include hoverOp(.3);
            }
            &:before {
              @include mq(tbl) {
                @include pd;
                background: url(#{$imagepath}arrow_right_white.svg);
                width: 16px;
                height: 16px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          &:after, &:before {
            @include pd;
            right: 11px;
            top: 50%;
            background-color: #aaa;
            width: 16px;
            height: 2px;
            @include mq(tbl) {
              display: none;
            }
          }
          &:after {
            width: 2px;
            height: 16px;
            right: 18px;
            margin-top: -7px;
            transition: transform $bezier $transition-speed;
          }
          &.active {
            &:after {
              transform: scaleY(0);
            }
          }
        }
        ul {
          display: none;
          @include fs(15,22);
          padding-top: 4px;
          padding-bottom: 15px;
          @include mq(tbl) {
            display: block !important;
            @include fs(14,21);
            padding: 20px 0 40px 25px;
          }
          + h2 {
            border-top: 1px solid #999;
            @include mq(tbl) {
              border-top: none;
            }
          }
          li {
            a {
              display: block;
              padding: 8px 0 8px 30px;
              position: relative;
              color: $white;
              @include mq(tbl) {
                padding: 5px 0 5px 32px;
                @include hoverOp(.3);
              }
              &:before {
                @include pd;
                height: 1px;
                width: 5px;
                background-color: $white;
                top: 18px;
                left: 20px;
                @include mq(tbl) {
                  top: 14px;
                }
              }
            }
          }
        }
      }
    }
    .footbanner {
      padding-top: 40px;
      @include mq(tbl) {
        padding-top: 50px;
      }
      a {
        background-color: white;
        padding: 15px 25px;
        display: grid;
        grid: "icon text" auto / auto 1fr;
        grid-gap: 16px;
        align-content: center;
        border-radius: 6px;
        margin-top: 20px;
        min-height: 60px;
        @include mq(tbl) {
          margin: 0 15px 30px;
          @include hoverOp(.5);
        }
        &:first-child {
          margin-top: 0;
        }
        i {
          grid-area: icon;
          display: flex;
          align-items: center;
          img {
            max-width: 70px;
            max-height: 30px;
          }
        }
        p {
          grid-area: text;
          display: flex;
          align-items: center;
          color: #231815;
          @include fs(14,20);
          font-family: $font-family-sub;
        }
      }
    }
    .footinfo {
      padding-top: 40px;
      @include mq(tbl) {
        padding-top: 0;
        width: 25%;
      }
      .corpinfo {
        p {
          text-align: center;
          @include mq(tbl) {
            text-align: left;
          }
          img {
            width: 200px;
            @include mq(tbl) {
              width: 185px;
            }
          }
        }
        .contact {
          margin-top: 30px;
          text-align: center;
          @include fs(13,20);
          @include mq(tbl) {
            @include fs(14,22);
            text-align: left;
            max-width: 190px;
          }
          address {
            .zip {
              display: block;
            }
          }
          ul {
            li {
              @include mq(tbl) {
                margin-right: 1em;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
      .sns {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        @include mq(tbl) {
          max-width: 190px;
        }
        li {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            @include mq(tbl) {
              @include hoverOp(.3);
            }
            svg {
              width: 30px;
              &.fb {
                fill: #2F77FC;
              }
              &.tw {
                fill: #000000;
                width: 24px;
              }
              &.yt {
                fill: #F0190C;
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 50px;
    color: white;
    @include container;
    padding-bottom: 30px;
    font-family: $font-family-sub;
    @include mq(tbl) {
      margin-top: 45px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    ul {
      @include mq(tbl) {
        display: flex;
      }
      li {
        margin-top: 14px;
        @include mq(tbl) {
          margin-top: 0;
          margin-right: 35px;
          &:last-child {
            margin-right: 0;
          }
        }
        &:first-child {
          margin-top: 0;
        }
        a {
          display: block;
          text-align: center;
          color: white;
          @include fs(12,20);
          @include mq(tbl) {
            @include hoverOp(.3);
          }
        }
      }
    }
    p {
      margin-top: 35px;
      text-align: center;
      @include fs(12,20);
      letter-spacing: 0;
      @include mq(tbl) {
        margin-top: 0;
        text-align: left;
      }
      span {
        display: block;
        @include mq(tbl) {
          display: inline;
          margin-left: 1em;
        }
      }
    }
  }
}
#pagetop {
  position: fixed;
  bottom: 24px;
  right: 18px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s $bezier;
  @include mq(tbl) {
    bottom: 85px;
    right: 30px;
  }
  a {
    transition: opacity .5s $bezier;
    @include mq(tbl) {
      width: 60px;
      height: 60px;
      &:hover {
        opacity: .5;
      }
    }
    img {
      width: 40px;
      height: 40px;
      @include mq(tbl) {
        width: 60px;
        height: 60px;
      }
    }
  }
  &.on {
    opacity: 1;
  }
}
