@import "variables";

@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin container($sp:20px,$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tbl) {
    padding-left: calc(50% - (#{$container} / 2));
    padding-right: calc(50% - (#{$container} / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-wide} / 2));
      padding-right: calc(50% - (#{$container-wide} / 2));
    }
  }
}
@mixin title {
  @include fs(24,32);
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  font-weight: $font-weight-bold;
  position: relative;
  @include mq(tbl) {
    @include fs(28,40);
    padding-bottom: 14px;
    &.large {
      @include fs(34,40);
    }
  }
  &:before {
    @include pd;
    width: 10%;
    height: 3px;
    background-color: $main;
    bottom: -2px;
    @include mq(tbl) {
      width: 100px;
    }
  }
}
@mixin button($size:medium) {
  display: inline-block;
  background-color: $main;
  color: $white !important;
  @include fs(16,28);
  padding: 8px 35px;
  border-radius: 6px;
  text-decoration: none !important;
  font-weight: $font-weight-bold;
  @include mq(tbl) {
    @include hoverOp(.5);
    padding: 10px 35px;
  }
  @if $size == small {
    @include fs(12,16);
    padding: 7px 15px;
    border-radius: 20px;
    color: #6C6F73 !important;
    background-color: #EBEBEB;
    @include mq(tbl) {
      @include fs(13,18);
      padding: 7px 20px;
    }
  }
  @else if $size == large {

  }
  @else if $size == apply {
    background-color: $red;
    padding: 12px 35px;
    min-width: 340px;
    @include fs(20,30);
    @include mq(tbl) {
      @include hoverOp(.5);
      padding: 20px 35px;
    }
  }
}
@mixin hoverOp($op:0.7) {
  transition: opacity .3s $bezier;
  &:hover {
    opacity: $op;
  }
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 6px;
    button {
      border: none;
      background-color: #CCC6B9;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
    }
    &.slick-active {
      button {
        background-color: #999078;
      }
    }
  }
}
@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: $gap-vert $gap-horiz;
}
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}