@charset "UTF-8";
@import "variables";
@import "mixins";

.top_news {
  margin-top: 40px;
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      align-items: flex-start;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include mq(tbl) {
        display: block;
        width: 180px;
      }
      .title {
        padding: 0 0 0 10px;
        @include mq(tbl) {
          padding: 0;
        }
        h1 {
          @include fs(13,13);
          color: $main;
        }
        p {
          @include fs(26,26);
          font-weight: $font-weight-bold;
          margin-top: 6px;
          @include mq(tbl) {
            @include fs(28,28);
            margin-top: 12px;
          }
        }
      }
      .link {
        margin-bottom: 2px;
        @include mq(tbl) {
          margin-top: 32px;
          margin-bottom: 0;
        }
        a {
          position: relative;
          padding-left: 24px;
          display: block;
          @include fs(14,20);
          @include hoverOp;
          &:before {
            @include pd;
            background: url(#{$imagepath}arrow_right_white.svg);
            width: 16px;
            height: 16px;
            top: 8px;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
    .m_newslist {
      margin-top: 25px;
      @include mq(tbl) {
        margin-top: 0;
        flex: 1;
      }
      .inner {
        padding: 0;
      }
    }
  }
}
.top_about {
  margin-top: 50px;
  background: rgb(255,255,255);
  background: linear-gradient(173deg, rgba(255,255,255,1) 14%, rgba(232,239,248,1) 14%);
  padding-bottom: 30px;
  position: relative;
  @include mq(tbl) {
    margin-top: 80px;
    padding-bottom: 70px;
    background: linear-gradient(173deg, rgba(255,255,255,1) 34%, rgba(232,239,248,1) 34%);
  }
  &:before {
    @include pd;
    width: 50%;
    height: 57vw;
    background: center top / contain no-repeat url(/images/logomark.svg);
    opacity: .08;
    top: 0;
    transform: translateY(-8vw);
    @include mq(tbl) {
      width: 690px;
      height: 400px;
      transform: translateY(0);
      top: -138px;
    }
  }
  .inner {
    @include container(20px,wide);
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 30px 30px;
      }
      .unit {
        position: relative;
        margin-top: 30px;
        background-color: $white;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          a {
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
        .desc {
          padding: 10px 0 55px;
          @include mq(tbl) {
            padding: 10px 0 85px;
          }
          .title {
            @include fs(20,30);
            position: relative;
            padding: 9px 25px;
            font-weight: $font-weight-bold;
            &:before {
              @include pd;
              background-color: $main;
              left: 0;
              top: 0;
              width: 6px;
              height: 100%;
              @include mq(tbl) {
                width: 8px;
              }
            }
            @include mq(tbl) {
              @include fs(24,36);
              @include hoverOp;
              padding: 12px 40px;
            }
          }
          .summary {
            @include fs(12,18);
            padding: 0 25px;
            color: #666;
            @include mq(tbl) {
              @include fs(15,24);
              padding: 0 40px;
            }
          }
          .link {
            position: absolute;
            right: 0;
            bottom: 10px;
            background-color: $sub;
            padding: 9px 45px;
            text-align: center;
            display: block;
            @include fs(12,18);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding: 10px 55px;
              @include fs(14,20);
              @include hoverOp;
              bottom: 20px;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_large.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 12px;
              top: 50%;
              right: 18px;
              transform: translateY(-50%);
              @include mq(tbl) {
                width: 25px;
                height: 14px;
              }
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
.top_engineer {
  padding-top: 35px;
  padding-bottom: 30px;
  background: center / cover url(/images/top_engineer_bg.jpg);
  position: relative;
  @include mq(tbl) {
    padding-top: 65px;
    padding-bottom: 60px;
  }
  .top_titles {
    .en {
      color: $white;
    }
    .title {
      color: $white;
      &:before {
        background-color: $white;
      }
    }
    .lead {
      p {
        color: $white;
      }
    }
  }
  .inner {
    @include container(20px,wide);
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 85px;
      }
      .unit {
        position: relative;
        margin-top: 30px;
        background-color: $white;
        @include mq(tbl) {
          margin-top: 70px;
          display: flex;
          background-color: transparent;
        }
        &:first-child {
          margin-top: 0;
        }
        &:nth-of-type(2n){
          @include mq(tbl) {
            flex-direction: row-reverse;
          }
        }
        .image {
          @include mq(tbl) {
            width: 51%;
            transform: translateY(-30px);
          }
          a {
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
        .desc {
          padding: 10px 0 55px;
          @include mq(tbl) {
            padding: 10px 0 85px;
            width: 49%;
            background-color: $white;
            position: relative;
          }
          .title {
            @include fs(20,30);
            position: relative;
            padding: 9px 25px;
            font-weight: $font-weight-bold;
            &:before {
              @include pd;
              background-color: $main;
              left: 0;
              top: 0;
              width: 6px;
              height: 100%;
              @include mq(tbl) {
                width: 8px;
              }
            }
            @include mq(tbl) {
              @include fs(26,36);
              @include hoverOp;
              padding: 12px 50px;
            }
          }
          .summary {
            @include fs(12,18);
            padding: 0 25px;
            color: #666;
            @include mq(tbl) {
              @include fs(15,24);
              padding: 0 50px;
              margin-top: 14px;
            }
          }
          ul {
            padding: 0 25px;
            margin-top: 20px;
            @include mq(tbl) {
              padding: 0 50px;
              margin-top: 20px;
            }
            li {
              margin-top: 12px;
              a {
                position: relative;
                display: block;
                padding: 0 0 0 24px;
                @include fs(14,20);
                color: $main;
                text-decoration: underline;
                &:hover {
                  @include mq(tbl) {
                    text-decoration: none;
                  }
                }
                @include mq(tbl) {
                  padding-left: 26px;
                  @include fs(16,26);
                }
                &:before {
                  @include pd;
                  background: url(#{$imagepath}arrow_right_white.svg);
                  width: 16px;
                  height: 16px;
                  top: 9px;
                  left: 0;
                  transform: translateY(-50%);
                  @include mq(tbl) {
                    top: 13px;
                  }
                }
              }
            }
          }
          .link {
            position: absolute;
            right: 0;
            bottom: 10px;
            background-color: $sub;
            padding: 9px 45px;
            text-align: center;
            display: block;
            @include fs(12,18);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding: 10px 55px;
              @include fs(14,20);
              @include hoverOp;
              bottom: 20px;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_large.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 12px;
              top: 50%;
              right: 18px;
              transform: translateY(-50%);
              @include mq(tbl) {
                width: 25px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.top_blog {
  padding-top: 40px;
  background: center / cover url(/images/top_blog_bg.jpg);
  position: relative;
  @include mq(tbl) {
    padding-top: 70px;
  }
  .m_articlelist {
    padding-bottom: 40px;
    .inner {
      @include container;
      .list {
        margin-top: 40px;
        @include mq(tbl) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(auto-fit, 1fr);
          grid-gap: 45px 45px;
        }
        .unit {
          display: flex;
          position: relative;
          margin-top: 30px;
          gap: 20px;
          @include mq(tbl) {
            margin-top: 0;
          }
          &:first-child {
            margin-top: 0;
          }
          .image {
            flex: 0 1 auto;
            a {
              display: block;
              background: #eaeaea center / 50% no-repeat url(/images/logomark.svg);
              height: 100px;
              width: 100px;
              @include mq(tbl) {
                height: 160px;
                width: 160px;
                @include hoverOp;
              }
              img {
                height: 100px;
                width: 100px;
                object-fit: cover;
                @include mq(tbl) {
                  height: 160px;
                  width: 160px;
                }
              }
            }
          }
          .desc {
            .cat {
              display: inline-block;
              padding-bottom: 0;
            }
            .date {
              display: inline-block;
            }
            .title {
              margin-top: 6px;
              @include mq(tbl) {
                margin-top: 8px;
              }
              &:before {
                @include mq(tbl) {
                  top: 13px;
                }
              }
              @include mq(tbl) {
                @include fs(18,27);
              }
            }
            .summary {
              @include mq(tbl) {
                @include fs(13,22);
              }
            }
          }
        }
      }
    }
  }
  .button {
    @include container;
    transform: translateY(50%);
    a {
      background-color: $main;
      padding: 9px 45px;
      text-align: center;
      display: block;
      @include fs(12,18);
      position: relative;
      font-weight: $font-weight-bold;
      color: $white;
      max-width: 460px;
      margin: 0 auto;
      @include mq(tbl) {
        padding: 18px 55px;
        @include fs(16,24);
        @include hoverOp;
      }
      &:before {
        @include pd;
        background: url(#{$imagepath}arrow_right_large_sub.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 12px;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        @include mq(tbl) {
          width: 33px;
          height: 19px;
        }
      }
    }
  }
}
.top_recruit {
  margin-top: 60px;
  @include mq(tbl) {
    margin-top: 110px;
  }
  .inner {
    @include container;
    background: linear-gradient(171deg, rgba(255,255,255,1) 17%, $main calc(17% + 1px), $main 85%, rgba(255,255,255,1) 85%);
    padding-top: 65px;
    padding-bottom: 67px;
    @include mq(tbl) {
      padding-top: 115px;
      padding-bottom: 127px;
      background: linear-gradient(173deg, rgba(255,255,255,1) 24%, $main calc(24% + 1px), $main 76%, rgba(255,255,255,1) calc(76% + 1px));
    }
    .unit {
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
      }
      .image {
        @include mq(tbl) {
          width: 63%;
        }
      }
      .desc {
        margin-top: 30px;
        color: $white;
        @include mq(tbl) {
          width: 31%;
          margin-top: 0;
          padding-top: 35px;
        }
        h2 {
          @include fs(24,24);
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(34,34);
          }
        }
        p {
          margin-top: 20px;
          @include fs(14,24);
          @include mq(tbl) {
            margin-top: 30px;
            @include fs(16,28);
          }
        }
        .link {
          margin-top: 20px;
          @include mq(tbl) {
            margin-top: 30px;
          }
          a {
            background-color: $sub;
            padding: 9px 45px;
            text-align: center;
            display: block;
            @include fs(12,18);
            position: relative;
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding: 13px 55px;
              @include fs(16,24);
              @include hoverOp;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_large.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 12px;
              top: 50%;
              right: 18px;
              transform: translateY(-50%);
              @include mq(tbl) {
                width: 33px;
                height: 19px;
              }
            }
          }
        }
      }
    }

  }
}
.top_titles {
  .en {
    display: block;
    text-align: center;
    padding-bottom: 10px;
    @include fs(14,14);
    color: $main;
    @include mq(tbl) {
      @include fs(14,14);
    }
  }
  .title {
    text-align: center;
    @include fs(26,34);
    font-family: $font-family-main;
    font-weight: $font-weight-bold;
    padding-top: 16px;
    position: relative;
    @include mq(tbl) {
      @include fs(34,46);
    }
    &:before {
      @include pd;
      width: 50px;
      height: 1px;
      background-color: $black;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
  .lead {
    @include container;
    margin-top: 25px;
    @include mq(tbl) {
      margin-top: 50px;
    }
    p {
      @include fs(22,34);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      display: inline-block;
      position: relative;
      padding-left: 1.2em;
      color: $main;
      @include mq(tbl) {
        @include fs(32,50);
      }
      &:before {
        content: '\2015\a0';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .recruitimage {
    text-align: center;
    margin-top: 35px;
    @include mq(tbl) {
      margin-top: 40px;
    }
    img {
      max-width: 300px;
      @include mq(tbl) {
        max-width: 600px;
      }
    }
  }
}
