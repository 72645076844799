@charset "UTF-8";
@import "variables";
@import "mixins";

.m_cattitle {
  padding: 84px 0 100px;
  position: relative;
  @include mq(tbl) {
    padding: 106px 0 150px;
  }
  &:before {
    @include pd;
    background-color: $main;
    left: 0;
    right: 30px;
    bottom: 0;
    top: 20px;
    z-index: 1;
    @include mq(tbl) {
      right: 80px;
      top: 40px;
    }
  }
  .bg {
    position: absolute;
    background-color: $sub;
    left: 0;
    right: 0;
    bottom: 20px;
    top: 0;
    z-index: 2;
    background-position: center;
    background-size: cover;
    @include mq(tbl) {
      left: 80px;
      bottom: 45px;
    }
  }
  .inner {
    @include container;
    z-index: 3;
    position: relative;
    .en {
      display: block;
      text-align: center;
      padding-bottom: 10px;
      @include fs(14,14);
      color: $white;
      @include mq(tbl) {
        @include fs(14,14);
      }
    }
    .title {
      text-align: center;
      @include fs(26,34);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      padding-top: 16px;
      position: relative;
      color: $white;
      @include mq(tbl) {
        @include fs(34,46);
      }
      &:before {
        @include pd;
        width: 50px;
        height: 1px;
        background-color: $white;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
  }
}
