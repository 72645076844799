@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_image_multi {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  &.set1 {
    .inner {
      @include mq(tb) {
        justify-content: center;
      }
      .image {
        @include mq(tbl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  &.set2 {
    .inner {
      @include grid(1,25px,0);
      @include mq(tbl) {
        @include grid(2,25px,25px);
      }
      .image {
        @include mq(tbl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,25px,25px);
      @include mq(tbl) {
        @include grid(3,25px,25px);
      }
      .image {
        @include mq(tbl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,25px,25px);
      @include mq(tbl) {
        @include grid(4,25px,25px);
      }
      .image {
        @include mq(tbl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  .inner {
    @include container;
    .image {
      &.shrink {
        margin-left: auto;
        margin-right: auto;
      }
      .caption {
        text-align: center;
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
}
