@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_member {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    @include grid(1,30px,0);
    @include mq(tb) {
      @include grid(2,26px,26px);
    }
    @include mq(tbl) {
      @include grid(3,40px,40px);
    }
    .unit {
      .photo {
        margin-bottom: 20px;
        @include mq(tbl) {
          margin-bottom: 30px;
        }
        img {
          aspect-ratio: 3 / 2;
          object-fit: cover;
        }
      }
      .info {
        h3 {
          @include fs(18,32);
          font-weight: $font-weight-bold;
          text-align: center;
          @include mq(tbl) {
            @include fs(20,34);
          }
          i {
            @include fs(12,22);
            display: block;
            @include mq(tbl) {
              @include fs(14,28);
            }
          }
        }
        .position {
          font-weight: bold;
          text-align: center;
          @include fs(12,22);
          @include mq(tbl) {
            @include fs(14,28);
          }
        }
        .profile {
          color: #999;
          margin-top: 15px;
          @include fs(11,18);
          @include mq(tbl) {
            @include fs(13,21);
            margin-top: 25px;
          }
        }
      }
      .desc {
        border-top: 1px solid $lightgray;
        margin: 20px 0 0;
        padding: 20px 0 20px;
        @include fs(12,22);
        @include mq(tbl) {
          margin: 20px 0 0;
          @include fs(14,28);
        }
        p {
          padding-bottom: 1em;
          &:last-child {
            padding-bottom: 0;
          }
        }
        + .button {
          margin-top: 0;
        }
      }
      .button {
        text-align: center;
        padding-bottom: 20px;
        margin-top: 20px;

        a {
          @include button;
        }
      }
    }
  }
}
