@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_anchor {
  margin-top: 60px;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        @include fs(14,22);
        margin: 0 15px 15px;
        white-space: nowrap;
        @include mq(tbl) {
          @include fs(16,26);
          margin: 0 15px 20px;
        }
        a {
          display: block;
          opacity: .5;
          position: relative;
          font-family: $font-family-main;
          text-decoration: none;
          color: $black;
          padding-left: 20px;
          &:before {
            @include pd;
            height: 6px;
            width: 10px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(#{$imagepath}arrow_bottom.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
          &:hover {
            @include mq(tbl) {
              opacity: 1;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
