@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_faq {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    .unit {
      border-top: 1px solid $lightgray;
      &:first-of-type {
        border-width: 1px;
      }
      &:last-of-type {
        border-bottom: 1px solid $lightgray;
      }
      .q {
        padding: 20px 45px 20px 40px;
        border-radius: $radius-small;
        position: relative;
        transition: border-radius $transition-speed $bezier,background $transition-speed $bezier, color $transition-speed $bezier;
        @include fs(14,26);
        @include mq(tbl) {
          padding: 25px 110px 25px 120px;
          cursor: pointer;
          @include fs(16,30);
          &:hover {
            color: $main;
          }
        }
        &.active {
          border-radius: $radius-small $radius-small 0 0;
          @include mq(tbl) {
            border-radius: $radius-default $radius-default 0 0;
          }
          &:after {
            transform: rotate(180deg);
          }
        }
        &:after {
          @include pd;
          width: 13px;
          height: 15px;
          background: center / contain no-repeat url(/images/arrow_open.svg);
          right: 15px;
          top: 50%;
          margin-top: -7px;
          transition: transform $transition-speed-fast $bezier;
          @include mq(tbl) {
            width: 15px;
            height: 18px;
            margin-top: -9px;
            right: 30px;
          }
        }
        &:before {
          @include pd;
          left: 10px;
          content: 'Q.';
          @include fs(17,22);
          @include mq(tbl) {
            @include fs(32,32);
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .a {
        padding: 0 32px 20px 40px;
        position: relative;
        display: none;
        @include fs(14,26);
        @include mq(tbl) {
          padding: 15px 110px 25px 120px;
          @include fs(16,30);
        }
        &:before {
          @include pd;
          left: 10px;
          top: 3px;
          content: 'A.';
          @include fs(17,22);
          color: $red;
          @include mq(tbl) {
            @include fs(32,32);
            left: 40px;
            top: 15px;
          }
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto 20px !important;
          &.mt-image-right {
            float: none !important;
            @include mq(tbl) {
              float: right !important;
              margin: 0 0 20px 20px !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq(tbl) {
              float: left !important;
              margin: 0 20px 20px 0 !important;
            }
          }
        }
        
        p {
          padding-bottom: 1.5em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
