@charset "UTF-8";
@import "variables";
@import "mixins";

.m_eyecatch {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
  }
}
