@charset "UTF-8";
@import "variables";
@import "mixins";

.m_cataloglist {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 40px 25px;
      }
      .unit {
        margin-top: 30px;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-of-type {
          margin-top: 0;
        }
        .main {
          display: flex;
          position: relative;
          margin-top: 30px;
          gap: 20px;
          @include mq(tbl) {
            margin-top: 0;
          }
          &:first-child {
            margin-top: 0;
          }
          .image {
            flex: 0 1 auto;
            width: 35%;
            @include mq(tbl) {
              width: 45%;
            }
            a {
              display: block;
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
          .desc {
            flex: 1;
            @include mq(tbl) {

            }
            .subtitle {
              margin-top: 10px;
              @include fs(12,18);
              color: #666;
              @include mq(tbl) {
                @include fs(13,21);
              }
            }
            .title {
              @include fs(15,24);
              font-weight: $font-weight-bold;
              @include mq(tbl) {
                @include fs(16,24);
                @include hoverOp;
              }
            }
            .format {
              @include fs(11,18);
              margin-top: 10px;
              @include mq(tbl) {
                @include fs(12,18);
              }
              img {
                width: 20px;
                height: 22px;
                object-fit: contain;
                margin-right: 8px;
              }
              span {
                text-transform: uppercase;
                letter-spacing: 0;
              }
              a {
                color: #666;
                @include mq(tbl) {
                  @include hoverOp;
                }
              }
            }
          }
        }
        .summary {
          @include fs(12,20);
          margin-top: 12px;
          @include mq(tbl) {
            @include fs(13,22);
            margin-top: 20px;
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
