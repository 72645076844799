@charset "UTF-8";
@import "variables";
@import "mixins";

.m_localnav_sub {
  padding: 40px 0 0;
  @include mq(tbl) {
    padding: 60px 0 0;
  }
  .m_archivenav + & {
    padding: 20px 0 0;
    @include mq(tbl) {
      padding: 40px 0 0;
    }
  }
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      background-color: #F3F5F6;
      padding: 15px 15px 5px;
      @include mq(tbl) {
        padding: 20px 40px 0px;
      }
      li {
        margin-right: 10px;
        margin-bottom: 10px;
        white-space: nowrap;
        @include mq(tbl) {
          margin: 0 20px 20px 0;
        }
        &.active,&.current_page_item {
          a {
            background-color: $color-main;
            color: white !important;
          }
        }
        a {
          @include button(small);
        }
      }
    }
  }
}
