@charset "UTF-8";
@import "variables";
@import "mixins";

.m_pagetitle {
  padding: 38px 0 0;
  @include mq(tbl) {
    padding: 66px 0 0;
  }
  .inner {
    @include container;
    .title {
      text-align: center;
      @include fs(26,34);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      padding-top: 16px;
      position: relative;
      @include mq(tbl) {
        @include fs(34,46);
        padding-top: 20px;
      }
      &:before {
        @include pd;
        width: 50px;
        height: 1px;
        background-color: $black;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
  }
}
