@charset "UTF-8";
@import "variables";
@import "mixins";

.m_newslist {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
    ul {
      border-top: 1px solid #CCCCCC;
      li {
        border-bottom: 1px solid #CCCCCC;
        a {
          display: block;
          padding: 13px 5px;
          @include mq(tbl) {
            display: flex;
            align-items: flex-start;
            padding: 14px 30px;
            position: relative;
            @include hoverOp(.5);
          }
          .date {
            @include fs(13,13);
            @include mq(tbl) {
              @include fs(15,15);
              padding-top: 3px;
            }
          }
          .cat {
            background-color: $main;
            border-radius: 12px;
            color: $white;
            @include fs(10,10);
            display: inline-block;
            padding: 4px 10px;
            margin-left: 5px;
            min-width: 100px;
            text-align: center;
            @include mq(tbl) {
              position: absolute;
              @include fs(11,11);
              min-width: 110px;
              margin: 0;
              left: 135px;
              padding: 6px 10px;
            }
          }
          h2 {
            margin-top: 7px;
            position: relative;
            padding-left: 22px;
            @include fs(14,20);
            @include mq(tbl) {
              margin: 0 0 0 155px;
              padding-left: 26px;
              @include fs(15,22);
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              width: 16px;
              height: 16px;
              top: 8px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 11px;
              }
            }
          }
        }
      }
    }
  }
}
