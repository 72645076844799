@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_panel {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  &.set2 {
    .inner {
      @include grid(1,60px,50px);
      @include mq(tbl) {
        @include grid(2,60px,50px);
      }
      .unit {
        .title {
          h3 {
            @include mq(tbl) {
              @include fs(18,28);
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(14,22);
          }
        }
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,35px,25px);
      @include mq(tbl) {
        @include grid(3,50px,25px);
      }
      .unit {
        .title {
          h3 {
            @include mq(tbl) {
              @include fs(18,28);
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(14,22);
          }
        }
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,30px,20px);
      @include mq(tbl) {
        @include grid(4,40px,20px);
      }
    }
  }
  &.set5 {
    .inner {
      @include grid(2,30px,20px);
      @include mq(tbl) {
        @include grid(5,40px,20px);
      }
    }
  }
  &.set4,&.set5 {
    .inner {
      .unit {
        .title {
          h3 {
            @include mq(tbl) {
              @include fs(16,26);
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(13,21);
          }
        }
      }
    }
  }
  .inner {
    @include container;
    .unit {
      @include mq(tbl) {
        max-width: 460px;
      }
      &:first-of-type {
        margin-top: 0;
      }
      .photo {
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
      }
      .title {
        margin-top: 22px;
        h3 {
          @include fs(20,30);
          font-weight: $font-weight-bold;
          color: $main;
          a {
            text-decoration: none;
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
      }
      .desc {
        @include fs(13,22);
        font-family: $font-family-main;
        margin-top: 10px;
        @include mq(tbl) {
          margin-top: 14px;
          @include fs(15,24);
        }
      }
      .link {
        margin-top: 15px;
        li {
          @include fs(13,18);
          @include mq(tbl) {
            @include fs(14,21);
          }
          a {
            display: inline-block;
            position: relative;
            padding: 6px 0 6px 25px;
            color: $main;
            text-decoration: underline;
            @include mq(tbl) {
              padding: 7px 0 7px 25px;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              width: 16px;
              height: 16px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            &:hover {
              @include mq(tbl) {
                text-decoration: none;
              }
            }
          }
        }
      }
      .button {
        margin-top: 20px;
      }
    }
  }
}
