@charset "UTF-8";
@import "variables";
@import "mixins";

.sitemap-list {
  padding-block: 10px 30px;
  > ul {
    @include mq(tbl) {
      columns: 2;
      column-gap: 30px;
    }
    > li {
      margin-top: 1.5em;
      @include mq(tbl) {
        margin-top: 1.75em;
        break-inside: avoid-column;
      }
      &:first-child {
        margin-top: unset;
      }
      a {
        position: relative;
        color: $body-text-color;
        text-decoration: none;
        font-weight: bold;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
        @include fs(15,24);
        @include mq(tbl) {
          @include fs(18,26);
          gap: 12px;
          &:hover {
            text-decoration: underline;
          }
        }
        &:before {
          content: '';
          background-image: url(#{$imagepath}arrow_right_main.svg);
          width: 16px;
          height: 1lh;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      ul {
        margin-left: 26px;
        margin-block: 1em 2em;
        @include mq(tbl) {
          margin-left: 58px;
          margin-block: 2em 2em;
        }
        li {
          margin-top: .75em;
          a {
            font-weight: normal;
            @include fs(14,21);
            @include mq(tbl) {
              @include fs(16,26);
            }
            &:before {
              background-image: url(#{$imagepath}arrow_right_white.svg);
              width: 15px;
              background-size: 15px auto;
            }
          }
        }
      }
    }
  }
}