@charset "UTF-8";
@import "variables";
@import "mixins";

.m_bannerlist {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 60px 60px;
      }
      .unit {
        position: relative;
        margin-top: 30px;
        border: 1px solid #ccc;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          a {
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
        .desc {
          padding: 20px 20px 45px;
          position: relative;
          @include mq(tbl) {
            padding: 20px 30px 55px;
          }
          .title {
            @include fs(16,24);
            position: relative;
            padding-left: 25px;
            font-weight: $font-weight-bold;
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 15px;
              }
            }
            @include mq(tbl) {
              @include fs(20,30);
              @include hoverOp;
            }
          }
          .summary {
            @include fs(12,18);
            margin-top: 10px;
            @include mq(tbl) {
              @include fs(14,20);
            }
          }
          .link {
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: $sub;
            padding: 9px 45px;
            text-align: center;
            display: block;
            @include fs(12,18);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding: 10px 55px;
              @include fs(14,20);
              @include hoverOp;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_large.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 12px;
              top: 50%;
              right: 18px;
              transform: translateY(-50%);
              @include mq(tbl) {
                width: 25px;
                height: 14px;
              }
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
