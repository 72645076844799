@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general, .component {
  strong, b {
    font-weight: $font-weight-bold;
  }
  a {
    color: $color-main;
    text-decoration: underline;
  }
  .text {
    word-break: break-all;
    p {
      @include fs(15,28);
      font-family: $font-family-main;
      padding-bottom: 2em;
      @include mq(tbl) {
        @include fs(16,30);
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      &.note {
        @include fs(12,18);
        color: rgba($body-text-color, 0.5);
        @include mq(tbl) {
          @include fs(14,28);
        }
      }
      &.heading {
        @include fs(22,32);
        font-family: $font-family-main;
        padding-bottom: .5em;
        color: $main;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(22,34);
          padding-bottom: .75em;
          padding-top: .5em;
        }
      }
      &.figure {
        @include fs(12,18);
        margin-top: 30px;
        padding-bottom: 35px;
        @include mq(tbl) {
          @include fs(15,26);
          margin-top: 60px;
          padding-bottom: 65px;
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: 6px !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              margin-bottom: 12px !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          margin-bottom: 0 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      & + .button{
        margin-top: 0;
      }
    }
    ul, ol {
      @include fs(15,28);
      font-family: $font-family-main;
      padding-bottom: 2em;
      @include mq(tbl) {
        @include fs(16,32);
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: 20px;
      }
    }
    ol {
      list-style: decimal;
      li {
        margin-left: 20px;
      }
    }
    .magazine {
      img {
        max-width: 350px;
      }
    }
    .button {
      margin-top: 10px;
      text-align: center;
      @include button($size:large);
      @include mq(tbl) {
        margin-top: 15px;
      }
    }
  }
  .image, .movie, .map {
    text-align: center;
    .caption {
      @include fs(12,20);
      text-align: center;
      margin-top: 8px;
      font-family: $font-family-sub;
      color: rgba($body-text-color, 0.75);
      @include mq(tbl) {
        @include fs(13,22);
        margin-top: 16px;
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .child {
      padding-bottom: 15px;
      @include mq(tbl) {
        padding-bottom: 25px;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &.magazine {
        text-align: center;
        padding: 20px;
        background-color: #E8EFF8;
        background-image: none;
        img {
          max-height: 200px;
          width: auto;
          @include mq(tbl) {
            max-height: 300px;
          }
        }
      }
    }
  }
  &.type_subheading {
    margin-top: 60px;
    @include mq(tbl) {
      margin-top: 90px;
    }
    .container {
      h3 {
        background-color: #e9f0f8;
        color: $main;
        @include fs(18,28);
        font-weight: bold;
        padding: 12px 15px 15px;
        margin-bottom: 20px;
        @include mq(tbl) {
          @include fs(24,40);
          padding: 13px 25px 14px;
        }
      }
    }
    & + .type_voices {
      margin-top: 0;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
  }
}


@import "general/heading";
@import "general/text";
@import "general/text_image";
@import "general/text_map";
@import "general/image";
@import "general/image_multi";
@import "general/movie";
@import "general/map";
@import "general/panel";
@import "general/speaker";
@import "general/column";
@import "general/table";
@import "general/button";
@import "general/anchor";
@import "general/num";
@import "general/wysiwyg";
@import "general/member";
@import "general/catalog";
@import "general/faq";
@import "general/pagelist";
@import "general/flow";
