@charset "UTF-8";
@import "variables";
@import "mixins";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $header-z-index;
  height: $header-height;
  background-color: $header-background-color;
  transition: transform $header-transition-speed $header-transition, background $header-transition-speed $header-transition;
  &:before {
    @include pd;
    background-color: white;
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
    height: $header-height;
    width: 100%;
    z-index: 1002;
    @include mq(tbl){
      height: $header-height-pc;
    }
  }
  @include mq(tbl){
    background-color: $header-background-color-pc;
    height: $header-height-pc;
  }
  &.is-down {
    transform: $header-is-down-transform;
  }
  .toppage & {
    background-color: $header-background-color-top;
    @include mq(tbl){
      background-color: $header-background-color-top-pc;
    }
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    height: $header-height;
    @if $header-store {
      padding-right: $header-store-width;
    }
    @include mq(tbl){
      padding-right: 0;
      height: $header-height-pc;
    }
    #logo {
      position: relative;
      margin-right: auto;
      z-index: $header-logo-z-index;
      padding: $header-logo-padding;
      @include mq(tbl){
        padding: $header-logo-padding-pc;
      }
      a {
        display: block;
        svg, img {
          &.sp {
            width: $header-logo-width;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            @include mq(tbl){
              display: none;
            }
          }
          &.pc {
            width: 160px;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            display: none;
            @include mq(tbl){
              display: inline-block;
            }
            @include mq(pc){
              width: 205px;
            }
          }
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      top: $header-height;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $gnav-z-index;
      background-color: $gnav-background-color;
      padding: $gnav-padding;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform $transition-speed $bezier;
      display: flex;
      flex-direction: column;
      @include mq(tbl){
        position: static;
        display: flex;
        flex-direction: row;
        padding: 0 20px 0 0;
        background-color: $gnav-background-color-pc;
        transform: translateX(0);
        overflow: visible;
        align-self: stretch;
      }
      @include mq(pc){
        padding: 0 15px 0 0;
      }
      &.show {
        transform: translateX(0);
      }
      .main {
        @include mq(tbl) {
          display: flex;
          height: 100%;
        }
        > li {
          font-family: $gnav-main-font-family;
          border-bottom: 1px solid #6B9ED2;
          font-weight: $font-weight-bold;
          position: relative;
          @include mq(tbl){
            border-bottom: none;
            padding: 22px 0;
            position: relative;
          }
          &.top {
            @include mq(tbl) {
              display: none;
            }
          }
          &.active {
            &:after {
              @include mq(tbl) {
                @include pd;
                left: 10px;
                right: 10px;
                bottom: 0;
                height: 5px;
                background-color: $main;
              }
              @include mq(pc) {
                left: 15px;
                right: 15px;
              }
            }
          }
          &.pc-active {
            .parent {
              background-color: $main;
              color: $white;
              + ul {
                opacity: 1;
                pointer-events: all;
              }
            }
          }
          .parent {
            display: block;
            padding: $gnav-main-list-padding;
            font-size: $gnav-main-list-font-size;
            line-height: $gnav-main-list-line-height;
            color: $gnav-main-list-font-color;
            @include mq(spl) {
              padding: $gnav-main-list-padding-spl;
              font-size: $gnav-main-list-font-size-spl;
              line-height: $gnav-main-list-line-height-spl;
              color: $gnav-main-list-font-color-spl;
            }
            @include mq(tbl) {
              padding: 0 20px;
              font-size: 1.4rem;
              line-height: $gnav-main-list-line-height-pc;
              color: $gnav-main-list-font-color-pc;
              height: 100%;
              display: flex;
              align-items: center;
              cursor: pointer;
              transition: all .1s $bezier;
            }
            &:hover {
              &:not(.touchdevice &) {
                color: $white;
              }
            }
            .toppage & {
              color: $gnav-main-list-font-color-top;
              @include mq(spl) {
                color: $gnav-main-list-font-color-top-spl;
              }
              @include mq(tbl) {
                color: $gnav-main-list-font-color-top-pc;
              }
            }
            span {
              position: relative;
              padding-left: 31px;
              @include mq(tbl){
                padding: 0;
              }
              &:before {
                @include pd;
                background: url(#{$imagepath}arrow_right_white.svg);
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                transition: transform $transition-speed $bezier;
                @include mq(tbl){
                  content: none;
                }
              }
            }
            &.current {
              span {
                opacity: $gnav-main-list-current-opacity;
                @include mq(tbl) {
                  position: relative;
                  opacity: 1;
                  &:after {
                    @include pd;
                    height: 1px;
                    width: 100%;
                    background-color: $gnav-main-list-current-underline-color;
                    bottom: $gnav-main-list-current-underline-position;
                  }
                }
              }
            }
            &.sp-active {
              span {
                &:before {
                  transform: translateY(-50%) rotate(270deg);
                }
              }
            }
          }
          ul {
            margin-left: 31px;
            padding-bottom: 15px;
            display: none;
            @include mq(tbl) {
              opacity: 0;
              display: block !important;
              pointer-events: none;
              position: absolute;
              z-index: 999;
              background-color: $main;
              left: 0;
              margin-left: 0;
              padding: 5px 20px 15px;
              min-width: 220px;
              transition: opacity .1s $bezier;
            }
            li {
              @include fs(13,17);
              border-top: 1px solid #4D86AF;
              @include mq(tbl) {
                &:first-child {
                  border-top: none;
                }
              }
              a {
                display: block;
                padding: 10px 0;
                color: $white;
                font-weight: $font-weight-normal;
                @include mq(tbl) {
                  white-space: nowrap;
                  transition: opacity .1s $bezier;
                  &:hover {
                    opacity: .5;
                  }
                }
              }
            }
          }
        }
      }
      .store {
        margin-top: 34px;
        background-color: $white;
        border-radius: 6px;
        padding: 24px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(tbl){
          display: none;
        }
        i {
          margin-right: 20px;
          svg {
            width: 32px;
            fill: $main;
          }
        }
        span {
          color: $color-main;
          font-size: 1.8rem;
          font-weight: $font-weight-bold;
        }
      }
      .sns {
        margin: $header-sns-margin;
        display: flex;
        @include mq(spl) {
          margin: $header-sns-margin-spl;
        }
        @include mq(tbl){
          display: none;
        }
        li {
          margin-right: $header-sns-list-margin-right;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $header-sns-icon-background-color;
            width: $header-sns-icon-width;
            height: $header-sns-icon-height;
            border-radius: $header-sns-icon-radius;
            @include mq(spl) {
              width: $header-sns-icon-width-spl;
              height: $header-sns-icon-height-spl;
            }
            svg {
              width: $header-sns-icon-image-width;
              fill: $header-sns-icon-color;
              @include mq(spl) {
                width: $header-sns-icon-image-width-spl;
              }
            }
          }
        }
      }
    }
    #header-search {
      font-family: $gnav-main-font-family;
      border-bottom: 1px solid #6B9ED2;
      font-weight: $font-weight-bold;
      position: relative;
      padding-top: 22px;
      @include mq(tbl){
        border-bottom: none;
        padding: 22px 0;
        position: relative;
      }
      &.pc-active {
        .parent {
          background-color: $main;
          color: $white;
          + .child {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
      .parent {
        display: none;
        @include mq(tbl) {
          display: block;
          padding: 0 20px;
          font-size: 1.4rem;
          line-height: $gnav-main-list-line-height-pc;
          color: $gnav-main-list-font-color-pc;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all .1s $bezier;
          font-weight: $font-weight-bold;
        }
        &:hover {
          &:not(.touchdevice &) {
            color: $white;
          }
        }
        span {
          position: relative;
          padding-left: 31px;
          @include mq(tbl){
            padding: 0;
          }
          &:before {
            @include pd;
            background: url(#{$imagepath}arrow_right_white.svg);
            width: 16px;
            height: 16px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            transition: transform $transition-speed $bezier;
            @include mq(tbl){
              content: none;
            }
          }
        }
      }
      .child {
        @include mq(tbl) {
          opacity: 0;
          display: block !important;
          pointer-events: none;
          position: absolute;
          z-index: 999;
          background-color: $main;
          left: 0;
          margin-left: 0;
          padding: 17px 17px 21px;
          width: 300px;
          transition: opacity .1s $bezier;
        }
        form.gsc-search-box {
          background-color: #4484C6;
          border-radius: 6px;
          position: relative;
          padding-inline: 20px;
          font-size: 1em;
          margin: unset;
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          gap: 11px;
          @include mq(tbl) {
            display: block;
          }
          &::before {
            content: '';
            display: block;
            background: url(#{$imagepath}icon_search.svg);
            background-size: contain;
            width: 15px;
            height: 15px;
            background-repeat: no-repeat;
            background-position: center;
            @include mq(tbl) {
              display: none;
            }
          }
          table.gsc-search-box {
            margin-bottom: unset;
            tr {
              .gsc-input {
                padding-right: unset;
                .gsc-input-box {
                  background-color: transparent;
                  border: none;
                  .gsib_a {
                    padding: unset;
                  }
                }
                input[type="text"] {
                  background-color: transparent !important;
                  border: unset !important;
                  font-size: 1.6rem !important;
                  color: $white !important;
                  padding: 16px 0 15px !important;
                  margin: unset !important;
                  height: auto !important;
                  background-image: none !important;
                  @include mq {
                    padding-block: 13px 12px !important;
                    font-size: 1.5rem !important;
                  }
                  &::placeholder {
                    color: $white;
                    opacity: .7;
                  }
                }
              }
              .gsc-search-button {
                display: none;
              }
              .gsc-clear-button {
                display: none;
              }
            }
          }
        }
        #cse-search-box {
          
          
          input[type="submit"] {
            display: none;
          }
        }
        p {
          @include fs(13,17);
          @include mq(tbl) {
            margin-top: 20px;
            margin-left: 2px;
          }
          a {
            display: block;
            padding: 20px 0 20px 25px;
            color: $white;
            font-weight: $font-weight-bold;
            position: relative;
            @include mq(tbl) {
              padding: 0 0 0 20px;
            }
            &::before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              background-size: contain;
              width: 13px;
              height: 13px;
              top: 50%;
              left: 0;
              translate: 0 -50%;
            }
            @include mq(tbl) {
              &:hover {
                opacity: .5;
              }
            }
          }
        }
      }
    }
    #lang {
      font-family: $gnav-main-font-family;
      font-weight: $font-weight-bold;
      @include fs(10,10);
      letter-spacing: 0;
      margin-right: 13px;
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        .float {
          opacity: 1;
          pointer-events: all;
        }
      }
      @include mq(tbl) {
        @include fs(13,13);
        margin-right: 30px;
      }
      p {
        padding-left: 15px;
        position: relative;
        @include mq(tbl) {
          padding-left: 20px;
        }
        &:before {
          @include pd;
          width: 11px;
          height: 11px;
          left: 0;
          background: no-repeat center / contain url(#{$imagepath}global.png);
          @include mq(tbl) {
            width: 15px;
            height: 15px;
          }
        }
      }
      .float {
        position: absolute;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-speed-fast $bezier;
        @include mq(tbl) {
          top: 70px;
        }
        &:before {
          @include pd;
          width: 12px;
          height: 12px;
          position: absolute;
          top: -6px;
          left: 50%;
          margin-left: -6px;
          background-color: $white;
          transform: rotate(45deg);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        }  
        ul {
          background-color: $white;
          padding: 3px;
          position: relative;
          z-index: 1012;
          text-align: center;
          @include mq(pc) {
            @include fs(12,12);
          }
          li {
            border-top: 1px solid #eee;
            &:first-child {
              border: none;
            }
            a {
              display: block;
              padding: 10px 10px;
              @include mq(tbl) {
                padding: 10px 20px;
                @include hoverOp(.5);
              }
            }
          }
        }
      }
      
    }
    #store {
      height: $header-height;
      min-width: $header-store-width;
      background-color: $header-store-background-color;
      padding: $header-store-padding;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: none;
      @include mq(tbl) {
        display: flex;
        height: $header-height-pc;
        background-color: $header-store-background-color-pc;
        padding: 0 20px;
        flex-direction: column;
        align-items: center;
        transition: background $transition-speed-fast $bezier;
        &:hover {
          background-color: $header-store-background-color-pc-hover;
        }
      }
      @include mq(pc) {
        padding: 0 40px;
      }
      i {
        display: block;
        padding: 0 0 6px;
        svg {
          width: $header-store-icon-width;
          fill: $header-store-icon-color;
          @include mq(tbl) {
            fill: $header-store-icon-color-pc;
            width: 29px;
          }
        }
      }
      span {
        @include mq(tbl) {
          display: block;
          color: $header-store-text-color-pc;
          font-size: 1.2rem;
          font-family: $header-store-font-family-pc;
          font-weight: $font-weight-bold;
          line-height: 1.2;
        }
        @include mq(pc) {
          font-size: 1.3rem;
        }
      }
    }
    #menuButton {
      position: absolute;
      right: 0;
      top: 0;
      width: $header-height;
      height: $header-height;
      cursor: pointer;
      z-index: $menu-z-index;
      background-color: $menu-backgound-color;
      @include mq(tbl){
        display: none;
      }
      span {
        background-color: $menu-bar-backgound-color;
        width: $menu-bar-width;
        height: $menu-bar-height;
        border-radius: $menu-bar-border-radius;
        position: absolute;
        left: ($header-height - $menu-bar-width) / 2;
        &.top {
          top: $menu-bar-top-pos;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.middle, &.back {
          top: $menu-bar-middle-pos;
          transform-origin: center;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.bottom {
          top: $menu-bar-bottom-pos;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
      }
      &.show {
        span {
          background-color: $white;
          &.top {
            transform: translateX(50%);
            background-color: transparent;
          }
          &.bottom {
            transform: translateX(-50%);
            background-color: transparent;
          }
          &.middle {
            transform: rotate(45deg) scaleX(1);
            transition: transform $transition-speed-fast $bezier $transition-speed-fast;
          }
          &.back {
            transform: rotate(-45deg) scaleX(1);
          }
        }
      }
    }
  }
}