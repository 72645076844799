@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_pagelist {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  &.bg {
    background-color: #F3F5F6;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0;
    @include mq(tbl) {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        @include grid(2,60px,40px);
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: 30px;
        gap: 20px;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #E2F3F8 center / 50% no-repeat url(/images/logomark.svg);
            height: 100px;
            width: 100px;
            @include mq(tbl) {
              height: 160px;
              width: 160px;
              @include hoverOp;
            }
            img {
              aspect-ratio: 1 / 1;
              object-fit: cover;
              &.default {
                // border: 1px solid $border;
              }
            }
          }
        }
        .desc {
          flex: 1;
          .date {
            font-weight: bold;
            @include fs(11,18);
            @include mq(tbl) {
              @include fs(13,21);
            }
          }
          .cat {
            margin-top: 10px;
            a {
              display: inline-block;
              margin: 0 5px 5px 0;
              font-weight: bold;
              background-color: $sub;
              border-radius: 15px;
              padding: 2px 10px;
              text-align: center;
              color: $white;
              text-decoration: none;
              @include fs(10,16);
              @include mq(tbl) {
                padding: 2px 16px;
                @include hoverOp;
                @include fs(12,18);
              }
            }
          }
          .title {
            color: $black;
            font-weight: bold;
            margin-top: 5px;
            position: relative;
            @include fs(18,32);
            @include mq(tbl) {
              @include hoverOp;
              margin-top: 10px;
              @include fs(20,34);
            }
            /*
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 15px;
              }
            }
            */
            a {
              color: inherit;
              text-decoration: none;
            }
          }
          .summary {
            margin-top: 6px;
            color: #999;
            @include fs(12,22);
            @include mq(tbl) {
              @include fs(14,28);
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
