@charset "UTF-8";
@import "variables";
@import "mixins";

.m_aform_title {
  margin-top: 45px;
  @include mq(tbl) {
    margin-top: 75px;
  }
  .container {
    text-align: center;
    h1 {
      @include fs(27,35);
      font-weight: bold;
      color: $main;
      @include mq(tbl) {
        @include fs(42,42);
      }
    }
  }
}
.m_aform {
  margin-top: 45px;
  @include mq(tbl) {
    margin-top: 75px;
  }
  .aform-header {
    display: none;
  }
  .aform-content {
    margin: 0 auto;
  }
  .aform-hdln {
    margin-top: 30px;
    margin-bottom: 30px;
    @include fs(22,32);
    font-weight: $font-weight-bold;
    position: relative;
    padding-bottom: 8px;
    border-bottom: 1px solid $main;
    @include mq(tbl) {
      margin-top: 60px;
      margin-bottom: 60px;
      @include fs(24,34);
    }
    + .aform-note {
      margin-top: 0;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
  }

  .aform-note {
    @include fs(13,18);
    margin-top: 15px;
    margin-bottom: 15px;
    @include mq(tbl) {
      @include fs(16,22);
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .aform-input[type="text"], .aform-text, .aform-email, .aform-password, .aform-tel, .aform-url, .aform-number, .aform-date, .aform-time, .aform-textarea, .aform-select, .aform-name, .aform-kana, .aform-zipcode, .aform-prefecture, .aform-calendar {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    @include fs(16,16);
  }

  .aform-field-option-text {
    padding: 5px;
    width: auto;
    box-sizing: border-box;
    margin-left: 5px;
    vertical-align: middle;
    @include fs(16,16);
  }

  .aform-checkbox, .aform-radio {
    margin-right: 5px;
    vertical-align: middle;
  }

  .aform-required {
    color: $red;
    @include fs(12,12);
    border: 1px solid $red;
    background-color: $white;
    font-weight: normal;
    margin-left: 5px;
    padding: 3px 6px;
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    @include mq(tbl) {
      @include fs(13,13);
    }
  }

  .aform-validation {
    color: #595959;
    @include fs(13,18);
    font-weight: normal;
  }

  .aform-input-example {
    color: #595959;
    @include fs(13,18);
    font-weight: normal;
    margin-bottom: 0.3em;
  }

  .aform-error {
    color: #D92B00;
  }

  .aform-input {
    &.error {
      color: #D92B00;
    }

    &.valid {}
  }

  /*----------------------------------

    layout

  ----------------------------------*/

  .aform-content {
    dl {
      margin: -1px 0 0;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      @include fs(13,18);
      @include mq(tbl) {
        @include fs(16,22);
        display: table;
        width: 100%;
        border-left: none;
        border-right: none;
      }
      dt {
        padding: 10px;
        background: #F3F5F6;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          padding: 25px;
          width: 30%;
          display: table-cell;
          vertical-align: middle;
        }
      }
      dd {
        margin-bottom: 0;
        padding: 10px 10px 10px;
        @include mq(tbl) {
          padding: 25px;
          width: 70%;
          display: table-cell;
        }
      }
      dt span.required {
        @include fs(11,11);
        padding: 0px 2px;
        border-radius: 3px;
      }
    }
  }

  .aform-name-ul, .aform-kana-ul {
    @include mq(tbl) {
      display: flex;
    }
    li {
      flex: 1;
      label {
        @include fs(13,18);
        color: #595959;
        margin-bottom: 0.3em;
        display: block;
        padding-top: .3em;
        @include mq(tbl) {
          padding-top: 0;
        }
      }
    }
  }
  .aform-name-ul li + li, .aform-kana-ul li + li {
    @include mq(tbl) {
      margin-left: 1em;
    }
  }
  .aform-horizontal-ul li {
    display: inline-block;
    margin-right: 1em;
  }

  .aform-twice-note {
    display: block;
    margin-top: 0.5em;
  }

  .aform-button-area {
    margin-top: 20px;
    padding: 15px 20px;
    text-align: center;
    @include mq(tbl) {
      margin-top: 30px;
    }
    input {
      appearance: none;
      margin: 0 6px;
      @include fs(16,16);
      background-color: $red;
      color: $white;
      font-weight: $font-weight-bold;
      border-radius: 6px;
      border: none;
      padding: 15px 50px;
      min-width: 250px;
      @include mq(tbl) {
        @include fs(20,20);
        padding: 22px 50px;
        min-width: 340px;
        @include hoverOp;
      }
    }
  }

  .aform-content {
    p.powerdby {
      text-align: right;
      @include fs(12,12);
      margin: 1em 0;
      a {
        color: #fff;
        padding: 0 5px;
        background: #BAD7E4;
        text-decoration: none;

        &:hover {
          background: #75AECA;
        }
      }
    }

    .aform-demo {
      text-align: center;
      margin: 2em 0;
      padding: 5px 10px;
      border: 1px solid #EE7BA0;
      background: #FDECF1;

      a {
        color: #903;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }

  /*----------------------------------

    aform_error

  ----------------------------------*/

  .aform-error-list {
    margin-bottom: 20px;

    dt {
      float: none;
      width: auto;
      margin-bottom: 10px;
      padding-left: 16px;
      @include fs(13,18);
      @include mq(tbl) {
        @include fs(16,22);
      }
    }

    dd {
      padding-left: inherit;
      margin-left: 16px;
      display: list-item;
    }
  }

  /*----------------------------------

    aform_finish

  ----------------------------------*/

  #aform_result {
    color: #c00;
    font-weight: bold;
    margin: 15px auto;
    padding: 10px 15px;
    @include fs(13,18);
    @include mq(tbl) {
      @include fs(16,22);
    }
    a {
      margin: 0 1em;
    }
  }

  /*----------------------------------

    additional-parts

  ----------------------------------*/

  /* aform-calendar */

  .aform-calendar {
    width: auto;
    margin-right: 5px;
    vertical-align: middle;

    + label {
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .aform-content .ui-datepicker-trigger {
    margin-left: 5px;
    vertical-align: text-top;
    width: auto;
  }

  .ui-datepicker .ui-widget-header {
    color: inherit;
  }

  /* aform-payment */

  .aform-payment {
    li {
      margin-bottom: 0.8em;
    }

    .aform-payment-description {
      margin-left: 22px;
      margin-top: 3px;

      table {
        th {
          font-weight: normal;
          padding: 2px 5px 2px 0;
          vertical-align: top;
          text-align: left;
        }

        td {
          padding: 2px 5px 2px 0;
        }
      }

      .aform-input {
        width: auto;
      }
    }
  }
}
