@charset "UTF-8";
@import "variables";
@import "mixins";

.m_extensiontitle {
  background-color: rgba(0,0,0,.3);
  background-blend-mode: soft-light;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 38px 0;
  @include mq(tbl) {
    padding: 66px 0;
  }
  .inner {
    @include container;
    .title {
      text-align: center;
      color: white;
      padding: 20px 0;
      @include fs(24,32);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(34,46);
      }
    }
    .lead {
      text-align: center;
      color: white;
      padding: 20px 0;
      @include fs(16,30);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(20,38);
      }
    }
  }
}
