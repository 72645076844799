@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_text {
  margin-top: 20px;
  @include mq(tbl) {
    margin-top: 35px;
  }
  .inner {
    @include container;
  }
  &.lead,
  &.lead_large {
    margin-top: 50px;
    padding-bottom: 10px;
    @include mq(tbl) {
      margin-top: 80px;
      padding-bottom: 20px;
    }
    .m_cattitle + & {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 65px;
      }
    }
    .inner {
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      .text {
        color: $color-main;
        @include mq(tbl) {
          padding: 0;
        }
        p {
          @include fs(22,34);
          font-family: $font-family-main;
          font-weight: $font-weight-bold;
          display: inline-block;
          position: relative;
          padding-left: 1.2em;
          @include mq(tbl) {
            @include fs(32,50);
          }
          &:before {
            content: '\2015\a0';
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  &.sp_left {
    text-align: left;
  }
  &.sp_center {
    text-align: center;
  }
  &.sp_right {
    text-align: right;
  }
  &.pc_left {
    @include mq(tbl) {
      text-align: left;
    }
  }
  &.pc_center {
    @include mq(tbl) {
      text-align: center;
    }
  }
  &.pc_right {
    @include mq(tbl) {
      text-align: right;
    }
  }
}
