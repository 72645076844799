@charset "UTF-8";
@import "variables";
@import "mixins";

.m_archivenav {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding: 0 0 10px;
      @include mq(tbl) {
        overflow: visible;
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        @include mq(tbl) {
          flex-wrap: wrap;
        }
        li {
          @include fs(11,18);
          white-space: nowrap;
          margin-left: -1px;
          position: relative;
          &:first-child {
            margin-left: 0;
          }
          @include mq(tbl) {
            @include fs(18,18);
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              color: $black;
              background-color: $white;
              border-color: #707070;
              &:before {
                background-color: $white;
                left: 0;
                right: 0;
              }
            }
          }
          a {
            display: block;
            position: relative;
            background-color: #F3F5F6;
            border: 1px solid #CCCCCC;
            border-bottom: none;
            padding: 11px 20px;
            color: #A0A1A1;
            letter-spacing: 0;
            font-weight: $font-weight-bold;
            transition: color $transition-speed $bezier;
            @include mq(tbl) {
              padding: 18px 36px;
            }
            &:before {
              @include pd;
              background-color: #707070;
              height: 1px;
              bottom: -1px;
              left: -1px;
              right: -1px;
            }
            &:hover {
              @include mq(tbl) {
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
